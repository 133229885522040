<template>
  <div class="container">
    <img class="img" src="@/assets/images/EmbolizationCalculation1.png" />
    <div class="box">
      <div class="title">
        <img src="@/assets/images/keyboard.png" />
        <span>请输入数值</span>
      </div>
      <div class="inputBox">
        <span class="label">动脉瘤体积(mm³)：</span>
        <van-field class="input" v-model="Volume" type="number" />
      </div>
      <div class="inputBox">
        <span class="label">弹簧圈个数：</span>
        <div class="input" @click="showPicker = true">
          <span>{{ Nnumber }}</span>
          <van-icon name="arrow-down" />
        </div>
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            value-key="name"
            @cancel="showPicker = false"
            @confirm="onConfirm"
          />
        </van-popup>
      </div>
      <template v-for="(item, index) in list">
        <div class="inputBox" :key="'Diameter' + index">
          <span class="label">({{ index + 1 }})一级螺旋直径(in)：</span>
          <van-field class="input" v-model="item.Diameter" type="number" />
        </div>
        <div class="inputBox" :key="'Length' + index">
          <span class="label">弹簧圈长度(cm)：</span>
          <van-field class="input" v-model="item.Length" type="number" />
        </div>
      </template>
      <div class="btn" @click="compute">计算</div>
    </div>
    <div class="box">
      <div class="title">
        <img src="@/assets/images/pinkEye.png" />
        <span>计算结果</span>
      </div>
      <div class="inputBox">
        <span class="info">弹簧圈总体积：<span class="number">{{ totalVolume }}mm³</span></span>
      </div>
      <div class="inputBox">
        <span class="info">填塞密度：<span class="number">{{ density }}%</span></span>
      </div>
    </div>
    <img class="img" src="@/assets/images/EmbolizationCalculation2.png" />
    <div class="statement">
      <p>声明：本工具仅供医疗专业人士使用，计算结果仅供医疗专业人士参考，最终以医疗专业人士的临床决策为准。</p><br>
      <!-- <p class="p">专业内容，仅供医学专业人士参考，严禁翻印及传播</p>
      <p class="p">152130-200907</p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmbolizationCalculation',
  data() {
    return {
      showPicker : false,
      columns: [],
      Volume: '', // 动脉瘤体积
      Nnumber: 1, // 弹簧圈个数
      list: [{
        Diameter: '', // 一级螺旋直径
        Length: '' // 弹簧圈长度
      }],
      totalVolume: 0,
      density: 0
    }
  },
  created() {
    for(let i = 1; i <= 20; i++) {
      this.columns.push({
        name: i + '个',
        value: i
      })
    }
  },
  methods: {
    onConfirm(e) {
      this.showPicker = false;
      this.Nnumber = e.value;
      let list = [];
      for(let i = 1; i <= this.Nnumber; i++) {
        list.push({
          Diameter: '', // 一级螺旋直径
          Length: '' // 弹簧圈长度
        })
      }
      this.list = list;
    },
    compute() {
      if(!this.Volume) {
        this.$toast('填写内容不能为空');
        return false;
      }

      let data = [];
      for(let i = 0; i < this.list.length; i++) {
        if(!this.list[i].Diameter || !this.list[i].Length) {
          this.$toast('填写内容不能为空');
          return false;
        } else {
          data.push({
            Volume: this.Volume,
            Nnumber: this.Nnumber,
            Diameter: this.list[i].Diameter,
            Length: this.list[i].Length
          })
        }
      }

      this.$axios.post('/Api/Api/Web/FillingCalculation/Calculation', data).then(res => {
        this.totalVolume = res.Data.sum.toFixed(2);
        this.density = res.Data.density.toFixed(2);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 55px;
  .img {
    display: block;
    width: 100%;
  }
  .box {
    margin: 20px 15px;
    border: 1px solid #7e7e7e;
    padding: 10px;
    border-radius: 6px;
    .title {
      display: flex;
      align-items: center;
      img {
        width: 30px;
        margin-right: 4px;
      }
      span {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .inputBox {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin: 10px 0;
      .label {
        display: inline-block;
        width: 145px;
        color: #7e7e7e;
        text-align: right;
      }
      .input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        border:1px solid #7e7e7e;
        width: 100px;
        height: 32px;
        border-radius: 3px;
        padding: 0 8px;
        box-sizing: border-box;
      }
      .info {
        color: #7e7e7e;
        margin: 5px 20px;
        .number {
          font-size: 16px;
          color: #E0008F;
          font-weight: bold;
        }
      }
    }
    .btn {
      font-size: 14px;
      text-align: center;
      width: 100px;
      height: 35px;
      line-height: 35px;
      background-color: #E0008F;
      color: #FFFFFF;
      margin: 16px auto 6px;
      border-radius: 6px;
    }
  }
  .statement {
    position: fixed;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    font-size: 11px;
    color: #FFFFFF;
    padding: 6px 10px;
    box-sizing: border-box;
    .p {
      text-align: right;
    }
  }
}
</style>